

.Acompanhar ion-header > * {
    display: block;
}

.Acompanhar ion-header .nome{
    /*font-size: 1.5em;*/
    font-size:medium;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.Acompanhar ion-header .parceiro {
    transform: translateY(.12em);
    font-size: small;
}

.Acompanhar ion-header .parceiro ion-icon{
    transform: translateY(.12em);
    font-size: small;
}

.Acompanhar ion-header > div.compartilhar{
    position: absolute;
    /*top: 1em;*/
    top:-10px;
    /*bottom: -5px;*/
    right: 5px;
    height: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--ion-color-cinza);
}

.Acompanhar ion-header > div.compartilhar ion-icon{
    font-size: 30px;
    transform: rotateY(180deg);
    
}

.Acompanhar ion-header > div.compartilhar ion-label{
   visibility: hidden;
    font-size: xx-small;
    font-family: Arial, Helvetica, sans-serif;
}

.Acompanhar .header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4em;
    background-color: #f2f2f2;

}
.Acompanhar .header .info{
    display: flex;
    width: 9em;
}

.Acompanhar .header .info > ion-icon{
    font-size: 40px;
    transform: translateY(.08em);
}

.Acompanhar .header .info > div{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.Acompanhar ion-list{
    margin-right: .5em;
    margin-left: .5em;
}

.Acompanhar ion-list ion-list-header{
    font-weight: bold;
    font-size: .9em;
    min-height: 1.7em;
    margin-top: 20px;
}

.Acompanhar ion-list .contentList{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: medium;
}

.Acompanhar ion-list .contentList h1{
    margin: 0;
    font-size: .8em;
}
.Acompanhar ion-list .contentList h2{
    margin: 0;
    font-size: 1em;
}
.Acompanhar ion-list .contentList .horario{
    width: 100%;
    display: flex;
    margin-top: .2em;
    justify-content: flex-start;
    
}

.Acompanhar ion-list .contentList .horario > *{
    min-width: 8em;
}

.Acompanhar .encerrar{

    width: 100%;
    position: fixed;
    display: flex;
    overflow: visible;
    z-index: 99999 !important;
    justify-content: space-around;
    /*background-color: var(--ion-color-branco);*/
    height: 3.5em;
    /*box-shadow: 0px 0px 5px black;*/
}

.ios .Acompanhar .encerrar{
    bottom: 63pt;  
}

.md .Acompanhar .encerrar{
  
    bottom: 15px;
}

.ios .takePhoto{
    top: 22pt;
}

.my-custom-class.Compartilhar .modal-wrapper .ion-page{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: white;
    padding-top: 4em;
    justify-content: flex-start;
    overflow: auto;
}

.my-custom-class.Compartilhar .modal-wrapper .headerCompartilhar{
    width: 100%;
    background-color: #e0e0e0;
    height: 4em;
    margin: 0px;
    position: fixed;
    top: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .headerCompartilhar{
    width: 100%;
    background-color: #e0e0e0;
    height: 4em;
    margin: 0px;
    position: fixed;
    top: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .ion-page > h1{
    color: var(--ion-color-cinza);
    margin: 0px;
    margin-top: .3em;
    margin-bottom: .3em;
    font-weight: bold;
    font-size: 1.25em;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid #e0e0e0;
}

.my-custom-class.Compartilhar .modal-wrapper .titulo{
    font-size: large;
    /*font-weight: bold;*/
    color: var(--ion-color-cinza);
    width: 100%;
    height: 100%;
    display: block;
    line-height: 2em;
    text-align: center;
}

.my-custom-class.Compartilhar .modal-wrapper .fechar{
    
    font-weight: bold;
    color: var(--ion-color-cinza);
    width: 4em;
    height: 4em;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    flex-direction: column;
    right: 0px
}
.my-custom-class.Compartilhar .modal-wrapper .fechar > *{
    font-size: 1em !important;
    align-self: center;
}
.my-custom-class.Compartilhar .modal-wrapper .fechar > span{
    font-size: .7em !important;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 1em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes{
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 60%;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes > *{
    width: 100%;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao{
    display: flex;
    height: 2em;
    flex-direction: row;
    justify-content: center;
    padding-right: 1em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao span{
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: var(--ion-color-cinza)
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao .barra{
    position: relative;
    width: calc(100% - 2em);
    height: .5em;
    background: #ddd;
    align-self: center;
    border-radius: .25em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao .barra .preenchimento{
    position: absolute;
    height: .5em;
    background: var(--ion-color-amarelo);
    width: 50%;
    left: 0;
    border-radius: .25em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--ion-color-cinza)
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media > *{
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media h1{
    font-size: 4em;
    margin: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar{
    display: flex;
    flex-direction: column;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar ion-label{
    color: var(--ion-color-cinza);
    width: 100%;
    display: block;
    text-align: center;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar ion-textarea{
    width: 100%;
    height: 2em;
    text-align: center;
    color: var(--ion-color-cinza);
    background: #e0e0e0;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar{
    display: flex;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar .avaliacaoEstrela{
    margin: .5em 0em;
    min-width: 50%;
    max-width: 80%;
    color: var(--ion-color-cinza);
    display: flex;
    justify-content: space-around;
    align-self: center;
    
}

.my-custom-class.Compartilhar .modal-wrapper .content{
    display: flex;
    flex-direction: row;
    width: 100%;
    display: block;
}

.my-custom-class.Compartilhar .modal-wrapper .comentarios .cabecalho{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .comentario{
    width: 100%;
    display: block;
    overflow-wrap: normal;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .cabecalho .usuario{
    margin-right: 1em;
    font-size: 1em;
    font-weight: bold;
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .cabecalho .data{
    margin-right: 1em;
    font-size: .8em;
    font-weight: lighter;
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper  .maisComentarios{
    margin-right: 1em;
    font-weight: lighter;
    align-self: center;
    margin: 1em
}

ion-footer ion-toolbar.acompanhar{
    padding-bottom: 0;
    --border-width: 0px;

}

ion-action-sheet.my-custom-class{
    background: transparent;
}

.ios .native-textarea.sc-ion-textarea-ios{
    padding: 0;
}

.my-custom-class .modal-wrapper ion-button{
    margin-left: 1em;
    margin-right: 1em;
    min-height: 35px;
}

.my-custom-class .modal-wrapper {
    max-height: 100%;
    /*box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.438);
    background: var(--ion-color-branco);*/
}

.my-custom-class .modal-wrapper .ion-page{
    justify-content: space-around;
    max-height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.my-custom-class.camera .modal-wrapper .ion-page video{
    position: absolute;
    width: 100%;
    height: 100%;
}

.my-custom-class.camera .modal-wrapper{
    /*background-color: black;*/
    background-color: white;
    width:100%;

}

.my-custom-class video{
    /*background-color: black;*/
    background-color: white;
    border: 1px solid #A40000;
    padding: 1%;
    margin: auto;
    width:80%;
    

    -webkit-clip-path: inset(45% 0% 45% 0%);
    clip-path: inset(45% 0% 45% 0%);

}

.my-custom-class.aguarde ion-label.helper{
    font-size: larger;
}

.my-custom-class.digitar ion-label.helper{
    padding-top: 20px;
    font-size: larger;
}

.my-custom-class.camera ion-label.helper{
    font-size: larger;
    position: absolute;
    top: 0em;
    padding: 1em;
    padding-bottom: .5em;
    width: 100%;
    /*color: white;*/
    color: black;
    text-align: center;
    /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.camera .actions{
    position: absolute;
    bottom: 0em;
    padding-bottom: 1em;
    padding-top: .5em;
    /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.camera .actions ion-label{
    color: white
}

.my-custom-class.digitar ion-label{
    color: var(--ion-color-cinza);
    text-align: center;
}

.my-custom-class.digitar ion-textarea{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    
}

.my-custom-class.digitar ion-textarea textarea, 
.my-custom-class.digitar ion-textarea .textarea-wrapper
{

    
    font-size:large;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: white;
    
  	text-align:center;
    border:0px solid #000;
    width: 80%;
    max-width: 90%;
    height: 100px;
    min-height: 70px;
    line-height: 20px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}


.my-custom-class.digitar ion-input{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 1em 0;
}

.my-custom-class.digitar ion-input textarea, 
.my-custom-class.digitar ion-input .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}




.my-custom-class.aguarde ion-label{
    color: var(--ion-color-cinza);
    text-align: center;
}

.my-custom-class.aguarde ion-textarea{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: blue;
    margin: 1em 0;
}

.my-custom-class.aguarde ion-textarea textarea, 
.my-custom-class.aguarde ion-textarea .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}


.my-custom-class.aguarde ion-input{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 1em 0;
}

.my-custom-class.aguarde ion-input textarea, 
.my-custom-class.aguarde ion-input .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}

