@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
.background-intro {
    width: 100%;
    height: 100%;
    background: var(--ion-color-amarelo) !important;
}

.Intro ion-icon {
    position: absolute;
    bottom: 0;
    width: 8em;
    height: 8em;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.Intro ion-img {
    position: absolute;
    bottom: 0;
    width: 19em;
    height: 19em;
    left: 50%;
    top: 45%;
    transform: translate3d(-50%, -50%, 0);
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
html, html * {
  font-family: 'Open Sans', sans-serif;
}


/** Ionic CSS Variables **/

:root {
  --ion-color-amarelo: #FFA508;
  --ion-color-laranja: #ff6a14;
  --ion-color-azul: #1d3460;
  --ion-color-branco: #e1e1e1;
  --ion-color-cinza: #333333;
  --ion-color-facebook: #3B5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
  --ion-color-google: #538AF0;
  --ion-color-google-rgb: 83, 138, 240;
  --ion-color-google-contrast: #000000;
  --ion-color-google-contrast-rgb: 0, 0, 0;
  --ion-color-google-shade: #4979d3;
  --ion-color-google-tint: #6496f2;
  --ion-color-primary: #FFA508;
  --ion-color-primary-rgb: 255, 165, 8;
  --ion-color-primary-contrast: #1d3460;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e09107;
  --ion-color-primary-tint: #ffae21;
  --ion-color-secondary: #ff6a14;
  --ion-color-secondary-rgb: 255, 106, 20;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e05d12;
  --ion-color-secondary-tint: #ff792c;
  --ion-color-tertiary: #1D3460;
  --ion-color-tertiary-rgb: 29, 52, 96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1a2e54;
  --ion-color-tertiary-tint: #344870;
  --ion-color-quartiary: rgba(0, 0, 0, 0);
  --ion-color-quartiary-shade: rgba(0, 0, 0, 0);
  --ion-color-quartiary-tint: rgba(0, 0, 0, 0);
  --ion-color-quartiary-rgb: 0, 0, 0;
  --ion-color-quartiary-contrast: #ffffff;
  --ion-color-quartiary-contrast-rgb: 255, 255, 255;
  --ion-color-success: #33ac1b;
  --ion-color-success-rgb: 51, 172, 27;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2d9718;
  --ion-color-success-tint: #47b432;
  --ion-color-warning: #F2F2F2;
  --ion-color-warning-rgb: 251, 198, 4;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ddae04;
  --ion-color-warning-tint: #fbcc1d;
  --ion-color-danger: #d4251c;
  --ion-color-danger-rgb: 212, 37, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bb2119;
  --ion-color-danger-tint: #d83b33;
  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-color-medium: #333333;
  --ion-color-medium-rgb: 51, 51, 51;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #2d2d2d;
  --ion-color-medium-tint: #474747;
  --ion-color-light: #FFFFFF;
  /*#e1e1e1;*/
  --ion-color-light-rgb: 225, 225, 225;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c6c6c6;
  --ion-color-light-tint: #F2F2F2;
  /*Reservado para uso exclusivo de IOS e Android*/
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 0px;
  /*--ion-safe-area-bottom: 22px;*/
}

ion-button {
  text-transform: none;
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
}

/*
   * Dark Colors
   * -------------------------------------------
   */

/*
@media (prefers-color-scheme: dark) {
  

  body {
    --ion-color-primary: #FFA508;
  --ion-color-primary-rgb: 255,165,8;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e09107;
  --ion-color-primary-tint: #ffae21;

  --ion-color-secondary: #ff6a14;
  --ion-color-secondary-rgb: 255,106,20;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e05d12;
  --ion-color-secondary-tint: #ff792c;

  --ion-color-tertiary: #1d3460;
  --ion-color-tertiary-rgb: 29,52,96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #1a2e54;
  --ion-color-tertiary-tint: #344870;

  --ion-color-success: #33ac1b;
  --ion-color-success-rgb: 51,172,27;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #2d9718;
  --ion-color-success-tint: #47b432;

  --ion-color-warning: #fbc604;
  --ion-color-warning-rgb: 251,198,4;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #ddae04;
  --ion-color-warning-tint: #fbcc1d;

  --ion-color-danger: #d4251c;
  --ion-color-danger-rgb: 212,37,28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #bb2119;
  --ion-color-danger-tint: #d83b33;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #333333;
  --ion-color-medium-rgb: 51,51,51;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #2d2d2d;
  --ion-color-medium-tint: #474747;

  --ion-color-light: #e1e1e1;
  --ion-color-light-rgb: 225,225,225;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #c6c6c6;
  --ion-color-light-tint: #e4e4e4;
  }

  // iOS Dark Theme

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  //Material Design Dark Theme

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/
.Cadastro{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Cadastro .input{
    position: relative;
    margin: .5em 2em;
}

.Cadastro ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.Cadastro ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.Cadastro #blocoTopo{
    width: 100%;
    height: 7em;
    background: var(--ion-color-cinza);
    margin-bottom: 5em;
}

.Cadastro .divisoria {
    position: relative;
    height: 1em;
    margin: .5em 2em .5em 2em;
}

.Cadastro .divisoria span{
    height: 2px;
    background: #CCC;
    width: 100%;
    display: block;
    transform: translateY(calc(1em / 2));
}

.Cadastro .divisoria ion-label{
    display: block;
    text-align: center;
    width: 7em;
    color: #CCC;
    background: white;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1em;
    line-height: 1em;
}

.Cadastro img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.Cadastro .jaTem{
    background: transparent;
    color: var(--ion-color-azul);
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: .25em 0em .25em 0em;
    
}

.Cadastro a{
    color: var(--ion-color-azul);
    text-decoration: none;
}

.Cadastro .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
ion-slides.OnboardingSlides {
    height: calc(100% - 10em);
}

ion-slides.OnboardingSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.OnboardingSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.OnboardingSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 50px;
    
}
ion-slides.OnboardingSlides ion-slide h1{
    width: 100%;
    font-size: 1.7em;
    font-family: Nunito;
    font-weight: bold;
    margin: 3em 1em 0.5em 1em;
}

ion-slides.OnboardingSlides ion-slide h2{
    width: 100%;
    font-size: 1.3em;
    margin: 0em 1em 2em 1em;
    padding: 0em 1em;
}

ion-slides.OnboardingSlides ion-slide img{
    width: 15em;
}

.Onboarding{
    display: flex;
    flex-direction: column;
}

.Onboarding ion-button{
    width: calc(100% - 4em);
    height: 3em;
    margin: 1em 2em;
    align-self: center;
}

.Onboarding ion-label{
    color: var(--ion-color-tertiary);
    width: 100%;
    
    display: block;
    text-align: center;

    transition: color ease 0.2s, display ease 0.5s;
}

.Onboarding ion-label.invisivel{
    color: transparent;
    display: none;
}

.Onboarding a{
    text-decoration: none;
}
.Login{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Login .input{
    position: relative;
    margin: .5em 2em;
}

.Login ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.Login ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .Login ion-input input{
    padding-left: 10px;
}

.Login #blocoTopo{
    width: 100%;
    height: 7em;
    background: var(--ion-color-cinza);
    margin-bottom: 5em;
}

.Login .divisoria {
    position: relative;
    height: 1em;
    margin: .5em 2em .5em 2em;
}

.Login .divisoria span{
    height: 1px;
    background: #CCC;
    width: 100%;
    display: block;
    transform: translateY(calc(1em / 2));
}

.Login .divisoria ion-label{
    display: block;
    text-align: center;
    width: 7em;
    color: #CCC;
    background: white;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1em;
    line-height: 1em;
}

.Login img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.Login a{
    background: transparent;
    color: var(--ion-color-azul);
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: .25em 0em .25em 0em;
}

.Login .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
.Login ion-button{
    background-color: var(--ion-color-branco);
}

.Login ion-button ion-label{
    width: 12em;
    text-align: left;
    margin-left: 1em;
}
ion-tab-bar{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5) ;
    display:flex;
}

.ion-page .can-go-back ion-tab-bar {
    display: flex;
}


ion-header ion-toolbar {
    color: #333333;
}

/*
ion-content.conta {
    --background: #F2F2F2;
}
*/

/*
ion-footer ion-item ion-note.footeritemnote{
    font-size: medium;
}
*/


/*

.ion-page .can-go-back ion-tab-bar {
    display: none;
}

ion-header{
    background-color:  var(--ion-color-primary);
}

ion-header ion-toolbar {
    color: #333333;
}



ion-content {
    display: inline-block;
    color: #333333;
}

ion-content ion-list{
    position: relative;
    display: inline-block;
    width: 100%;
    top:0px;
    bottom:100px;
    padding: 0px;
}

ion-content ion-list ion-item{
    --highlight-height: 0px;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    border: 0px solid var(--ion-color-cinza);
    border-radius: 0px;
    margin-top:10px;
    margin-left: 20px;
    margin-right: 20px;
}

ion-content ion-list ion-radio-group ion-list-header{
    --highlight-height: 0px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 0px;
    margin: 0px;
    border: 0px solid var(--ion-color-cinza);
    border-radius: 0px;
    margin-top:10px;
    margin-left: 20px;
    margin-right: 20px;
}

ion-footer ion-button{
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}
*/
/*
ion-header{
    background-color:  var(--ion-color-primary);
}

ion-header ion-toolbar {
    color: #333333;
}



ion-content {
    display: inline-block;
    color: #333333;
}

ion-content ion-list{
    position: relative;
    display: inline-block;
    width: 100%;
    top:0px;
    bottom:100px;
    padding: 0px;
}

ion-content ion-list ion-item{
    --highlight-height: 0px;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    border: 0px solid var(--ion-color-cinza);
    border-radius: 0px;
    margin-top:10px;
    margin-left: 20px;
    margin-right: 20px;
}

ion-content ion-list ion-radio-group ion-list-header{
    --highlight-height: 0px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 0px;
    margin: 0px;
    border: 0px solid var(--ion-color-cinza);
    border-radius: 0px;
    margin-top:10px;
    margin-left: 20px;
    margin-right: 20px;
}

ion-footer ion-button{
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}
*/
ion-slides.InstrucoesSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.InstrucoesSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.InstrucoesSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

ion-slides.InstrucoesSlides ion-slide ion-card{
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.InstrucoesSlides ion-slide ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.InstrucoesSlides ion-slide ion-card-title{
    font-weight: bold;
    font-size: larger;
    text-align: left;
}

ion-card-content div.cardInstrucoes{
    display:flex;
    margin-top:5px;    
}

.ios ion-label.instrucoes
{
    margin-top: 20px;

}



/*
ion-header{
    background-color:  var(--ion-color-primary);
}

ion-header ion-toolbar {
    color: #333333;
}



ion-content {
    display: inline-block;
    color: #333333;
}

ion-content ion-list{
    position: relative;
    display: inline-block;
    width: 100%;
    top:0px;
    bottom:100px;
    padding: 0px;
}



ion-content ion-list ion-radio-group ion-list-header{
    --highlight-height: 0px;
    padding: 0px;
    padding-left: 15px;
    padding-right: 0px;
    margin: 0px;
    border: 0px solid var(--ion-color-cinza);
    border-radius: 0px;
    margin-top:10px;
    margin-left: 20px;
    margin-right: 20px;
}

ion-footer ion-button{
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}
*/
ion-slides.InstrucoesSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.InstrucoesSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.InstrucoesSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

ion-slides.InstrucoesSlides ion-slide ion-card{
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.InstrucoesSlides ion-slide ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.InstrucoesSlides ion-slide ion-card-title{
    font-weight: bold;
    font-size: larger;
    text-align: left;
}

ion-card-content div.cardInstrucoes{
    display:flex;
    margin-top:5px;    
}

.ios ion-label.instrucoes
{
    margin-top: 20px;

}




.Acompanhar ion-header > * {
    display: block;
}

.Acompanhar ion-header .nome{
    /*font-size: 1.5em;*/
    font-size:medium;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.Acompanhar ion-header .parceiro {
    transform: translateY(.12em);
    font-size: small;
}

.Acompanhar ion-header .parceiro ion-icon{
    transform: translateY(.12em);
    font-size: small;
}

.Acompanhar ion-header > div.compartilhar{
    position: absolute;
    /*top: 1em;*/
    top:-10px;
    /*bottom: -5px;*/
    right: 5px;
    height: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--ion-color-cinza);
}

.Acompanhar ion-header > div.compartilhar ion-icon{
    font-size: 30px;
    transform: rotateY(180deg);
    
}

.Acompanhar ion-header > div.compartilhar ion-label{
   visibility: hidden;
    font-size: xx-small;
    font-family: Arial, Helvetica, sans-serif;
}

.Acompanhar .header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4em;
    background-color: #f2f2f2;

}
.Acompanhar .header .info{
    display: flex;
    width: 9em;
}

.Acompanhar .header .info > ion-icon{
    font-size: 40px;
    transform: translateY(.08em);
}

.Acompanhar .header .info > div{
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.Acompanhar ion-list{
    margin-right: .5em;
    margin-left: .5em;
}

.Acompanhar ion-list ion-list-header{
    font-weight: bold;
    font-size: .9em;
    min-height: 1.7em;
    margin-top: 20px;
}

.Acompanhar ion-list .contentList{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: medium;
}

.Acompanhar ion-list .contentList h1{
    margin: 0;
    font-size: .8em;
}
.Acompanhar ion-list .contentList h2{
    margin: 0;
    font-size: 1em;
}
.Acompanhar ion-list .contentList .horario{
    width: 100%;
    display: flex;
    margin-top: .2em;
    justify-content: flex-start;
    
}

.Acompanhar ion-list .contentList .horario > *{
    min-width: 8em;
}

.Acompanhar .encerrar{

    width: 100%;
    position: fixed;
    display: flex;
    overflow: visible;
    z-index: 99999 !important;
    justify-content: space-around;
    /*background-color: var(--ion-color-branco);*/
    height: 3.5em;
    /*box-shadow: 0px 0px 5px black;*/
}

.ios .Acompanhar .encerrar{
    bottom: 63pt;  
}

.md .Acompanhar .encerrar{
  
    bottom: 15px;
}

.ios .takePhoto{
    top: 22pt;
}

.my-custom-class.Compartilhar .modal-wrapper .ion-page{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: white;
    padding-top: 4em;
    justify-content: flex-start;
    overflow: auto;
}

.my-custom-class.Compartilhar .modal-wrapper .headerCompartilhar{
    width: 100%;
    background-color: #e0e0e0;
    height: 4em;
    margin: 0px;
    position: fixed;
    top: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .headerCompartilhar{
    width: 100%;
    background-color: #e0e0e0;
    height: 4em;
    margin: 0px;
    position: fixed;
    top: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .ion-page > h1{
    color: var(--ion-color-cinza);
    margin: 0px;
    margin-top: .3em;
    margin-bottom: .3em;
    font-weight: bold;
    font-size: 1.25em;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: 1px solid #e0e0e0;
}

.my-custom-class.Compartilhar .modal-wrapper .titulo{
    font-size: large;
    /*font-weight: bold;*/
    color: var(--ion-color-cinza);
    width: 100%;
    height: 100%;
    display: block;
    line-height: 2em;
    text-align: center;
}

.my-custom-class.Compartilhar .modal-wrapper .fechar{
    
    font-weight: bold;
    color: var(--ion-color-cinza);
    width: 4em;
    height: 4em;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    flex-direction: column;
    right: 0px
}
.my-custom-class.Compartilhar .modal-wrapper .fechar > *{
    font-size: 1em !important;
    align-self: center;
}
.my-custom-class.Compartilhar .modal-wrapper .fechar > span{
    font-size: .7em !important;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 1em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes{
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 60%;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes > *{
    width: 100%;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao{
    display: flex;
    height: 2em;
    flex-direction: row;
    justify-content: center;
    padding-right: 1em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao span{
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: var(--ion-color-cinza)
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao .barra{
    position: relative;
    width: calc(100% - 2em);
    height: .5em;
    background: #ddd;
    align-self: center;
    border-radius: .25em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .divisaoAvaliacoes .avaliacao .barra .preenchimento{
    position: absolute;
    height: .5em;
    background: var(--ion-color-amarelo);
    width: 50%;
    left: 0;
    border-radius: .25em;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--ion-color-cinza)
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media > *{
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliacoes .media h1{
    font-size: 4em;
    margin: 0;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar{
    display: flex;
    flex-direction: column;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar ion-label{
    color: var(--ion-color-cinza);
    width: 100%;
    display: block;
    text-align: center;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar ion-textarea{
    width: 100%;
    height: 2em;
    text-align: center;
    color: var(--ion-color-cinza);
    background: #e0e0e0;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar{
    display: flex;
}

.my-custom-class.Compartilhar .modal-wrapper .avaliarComentar .avaliacaoEstrela{
    margin: .5em 0em;
    min-width: 50%;
    max-width: 80%;
    color: var(--ion-color-cinza);
    display: flex;
    justify-content: space-around;
    align-self: center;
    
}

.my-custom-class.Compartilhar .modal-wrapper .content{
    display: flex;
    flex-direction: row;
    width: 100%;
    display: block;
}

.my-custom-class.Compartilhar .modal-wrapper .comentarios .cabecalho{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .comentario{
    width: 100%;
    display: block;
    overflow-wrap: normal;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .cabecalho .usuario{
    margin-right: 1em;
    font-size: 1em;
    font-weight: bold;
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper  .comentarios ion-item .cabecalho .data{
    margin-right: 1em;
    font-size: .8em;
    font-weight: lighter;
    align-self: center;
}

.my-custom-class.Compartilhar .modal-wrapper  .maisComentarios{
    margin-right: 1em;
    font-weight: lighter;
    align-self: center;
    margin: 1em
}

ion-footer ion-toolbar.acompanhar{
    padding-bottom: 0;
    --border-width: 0px;

}

ion-action-sheet.my-custom-class{
    background: transparent;
}

.ios .native-textarea.sc-ion-textarea-ios{
    padding: 0;
}

.my-custom-class .modal-wrapper ion-button{
    margin-left: 1em;
    margin-right: 1em;
    min-height: 35px;
}

.my-custom-class .modal-wrapper {
    max-height: 100%;
    /*box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.438);
    background: var(--ion-color-branco);*/
}

.my-custom-class .modal-wrapper .ion-page{
    justify-content: space-around;
    max-height: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.my-custom-class.camera .modal-wrapper .ion-page video{
    position: absolute;
    width: 100%;
    height: 100%;
}

.my-custom-class.camera .modal-wrapper{
    /*background-color: black;*/
    background-color: white;
    width:100%;

}

.my-custom-class video{
    /*background-color: black;*/
    background-color: white;
    border: 1px solid #A40000;
    padding: 1%;
    margin: auto;
    width:80%;
    

    -webkit-clip-path: inset(45% 0% 45% 0%);
    clip-path: inset(45% 0% 45% 0%);

}

.my-custom-class.aguarde ion-label.helper{
    font-size: larger;
}

.my-custom-class.digitar ion-label.helper{
    padding-top: 20px;
    font-size: larger;
}

.my-custom-class.camera ion-label.helper{
    font-size: larger;
    position: absolute;
    top: 0em;
    padding: 1em;
    padding-bottom: .5em;
    width: 100%;
    /*color: white;*/
    color: black;
    text-align: center;
    /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.camera .actions{
    position: absolute;
    bottom: 0em;
    padding-bottom: 1em;
    padding-top: .5em;
    /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.camera .actions ion-label{
    color: white
}

.my-custom-class.digitar ion-label{
    color: var(--ion-color-cinza);
    text-align: center;
}

.my-custom-class.digitar ion-textarea{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    
}

.my-custom-class.digitar ion-textarea textarea, 
.my-custom-class.digitar ion-textarea .textarea-wrapper
{

    
    font-size:large;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: white;
    
  	text-align:center;
    border:0px solid #000;
    width: 80%;
    max-width: 90%;
    height: 100px;
    min-height: 70px;
    line-height: 20px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}


.my-custom-class.digitar ion-input{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 1em 0;
}

.my-custom-class.digitar ion-input textarea, 
.my-custom-class.digitar ion-input .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}




.my-custom-class.aguarde ion-label{
    color: var(--ion-color-cinza);
    text-align: center;
}

.my-custom-class.aguarde ion-textarea{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: blue;
    margin: 1em 0;
}

.my-custom-class.aguarde ion-textarea textarea, 
.my-custom-class.aguarde ion-textarea .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}


.my-custom-class.aguarde ion-input{
    color: var(--ion-color-cinza);
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 1em 0;
}

.my-custom-class.aguarde ion-input textarea, 
.my-custom-class.aguarde ion-input .textarea-wrapper
{
    width: 100%;
    height: 100%;
    background-color: white;
}


div.OperacoesSlides {
    padding-top:0px;
    height: calc(100% - 3em);
    background:#F2F2F2;
}

div.OperacoesSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

div.OperacoesSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
div.OperacoesSlides{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}


ion-segment ion-label{
    color: #000;
    font-size:17px;
    font-weight: bold;
}

.Operacoes{
    display: flex;
    flex-direction: column;
    overflow: hidden;

}

.Operacoes ion-label.invisivel{
    color: transparent;
    display: none;
}

.Operacoes a{
    text-decoration: none;
}

div.OperacoesSlides{

    position: relative;
  height: auto;
  min-height: 100% !important;
  
}

div.OperacoesSlides ion-card{
    
    width: calc(100% - 2em);
    margin: 1em;
    margin-top: 2em;
    overflow: visible;
}


div.OperacoesSlides ion-card-title{
    /*font-weight: bold;
    font-size: medium;*/
    text-align: left;
}

ion-card {
    border-radius: 10px;
}

ion-card.card{
    background-color: #F2F2F2;
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho{
    text-align: left;
    font-size: large;
}

div.OperacoesSlides ion-card-content{
    /*display: flex;*/
    text-align: left;
    /*margin: 0px;*/
}



ion-card-content div.operacao{
    display:flex;
    margin-top:5px;
    
}

ion-card-content div.operacao ion-label{
    padding-right: 10px;
    width: 100px;
    overflow: hidden;
}

ion-card-content div.operacao ion-label.operacaoTexto{
    font-weight: bold;
    color: black;
    width:auto;
}

ion-card-content div.operacao ion-icon{
    margin-left:auto;
    width:18px;
    height:18px;
}


ion-card-content div.cardTail{
    margin-top: 15px;
    /*display: inline-block;*/
    display:flex;
}

div.OperacoesSlides ion-card-content span{
    display:block;
    margin: auto;
    text-align: center;
    font-weight: bold;
    color: var(--ion-color-primary);

}

div.OperacoesSlides .status{
    position: absolute;
    bottom: 15px;
    right: 10px;
    display: flex;
    font-size: x-small;
}

div.OperacoesSlides ion-card-subtitle{
    /*display: flex;*/
    text-align: center;
    /*margin: 0px;*/
}

div.OperacoesSlides ion-card-subtitle > *{
    align-self: center;
    margin-right: .3em;
}

div.OperacoesSlides ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 10px;
}

div.tempo div.duracao{
    margin: 0;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: -10px;
    
}

div.tempo div.duracaoTexto{
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align:center;
}

div.tempo{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -30px;
    margin: auto;
    right: 10px;
    left: 10px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--ion-color-tertiary); /*#7fee1d;*/
    z-index: 999;
}

div.loja{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin:0;
    padding: 4px;
    left: 0px;
    color: black;
    /*left: 0px;    */
    width: auto;
    height: 25px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    background: var(--ion-color-primary); /*#7fee1d;*/
    /*z-index: 999;*/
}

div.tempo2{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin:auto;
    right: 10px;
    /*left: 0px;    */
    width: 200px;
    height: 25px;
    border-radius: 10px 10px 0 0;
    background: var(--ion-color-tertiary); /*#7fee1d;*/
    z-index: 999;
}

div.tempo2 div.duracao{
    margin: 0;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: -10px;
    
}

div.tempo2 div.duracaoTexto{
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align:center;
}

@keyframes piscarverde {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(21, 255, 0);;
        background: rgb(18, 221, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscarvermelho {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 30, 0);;
        background: rgb(221, 0, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscaramarelo {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 217, 0);;
        background: rgb(255, 196, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

div.OperacoesSlides {
    background: #F2F2F2;
}

idiv.OperacoesSlides .pisca{
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

div.OperacoesSlides .piscaverde{
    animation-name: piscarverde;
}

div.OperacoesSlides .piscavermelho{
    animation-name: piscarvermelho;
}

div.OperacoesSlides .piscaamarelo{
    animation-name: piscaramarelo;
}
button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
}

ion-content.equipecontent {
    --background: #F2F2F2;
}

.Equipe {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Equipe ion-label.invisivel {
    color: transparent;
    display: none;
}

.Equipe a {
    text-decoration: none;
}

ion-slides.EquipeSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.EquipeSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.EquipeSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.EquipeSlides ion-slide ion-card ion-card-header {
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.EquipeSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: larger;
    text-align: left;
}



ion-card.card {
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho {
    text-align: left;
    font-size: large;
}


ion-slides.EquipeSlides ion-slide .status {
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;

    right: 10px;
    display: flex;
    font-size: x-small;
}

ion-card.equipeCard {
    margin-top: 30px;
}

ion-card-content div.equipe {
    display: flex;
    margin-top: 5px;
}

ion-card-content div.equipe ion-label {
    padding-right: 10px;
    min-width: 100px;
    overflow: hidden;
    text-align: left;
}

ion-card-content div.equipe ion-label.equipeTexto {
    font-weight: bold;
    overflow: hidden;
    color: black;
    width: auto;
    overflow: hidden;

}


ion-slides.EquipeSlides ion-slide .pisca {
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.EquipeSlides ion-slide .piscaverde {
    animation-name: piscarverde;
}

ion-slides.EquipeSlides ion-slide .piscavermelho {
    animation-name: piscarvermelho;
}

ion-slides.EquipeSlides ion-slide .piscaamarelo {
    animation-name: piscaramarelo;
}
/*
:root {
    --ion-safe-area-top: 20px;
    --ion-safe-area-bottom: 22px;
  }
*/


/*
:root {
    --ion-safe-area-top: 20px;
    --ion-safe-area-bottom: 22px;
  }
*/

.agendamentoCadContent .calendar input {
  border: none;
  outline: none;
  text-align: left;
  width: 80px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin: 0;
  margin-left: 8px;
  padding: 0;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
  background: white;
}

.agendamentoCadContent .calendar ion-select {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}


.paginaAgendamentos input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

.paginaAgendamentos ion-select {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}

.react-datepicker-popper {
    z-index: 20 !important;
}

ion-content.agendamentoscontent {
    --background: #F2F2F2;
}

.Agendamentos {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Agendamentos ion-label.invisivel {
    color: transparent;
    display: none;
}

.Agendamentos a {
    text-decoration: none;
}

ion-slides.AgendamentosSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.AgendamentosSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.AgendamentosSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.AgendamentosSlides ion-slide ion-card ion-card-header {
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.AgendamentosSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: larger;
    text-align: left;
}



ion-card.card {
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho {
    text-align: left;
    font-size: large;
}


ion-slides.AgendamentosSlides ion-slide .status {
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;

    right: 10px;
    display: flex;
    font-size: x-small;
}

ion-card.agendamentosCard {
    margin-top: 30px;
}

ion-card-content div.agendamentos {
    display: flex;
    margin-top: 5px;
}

ion-card-content div.agendamentos ion-label {
    padding-right: 10px;
    min-width: 100px;
    overflow: hidden;
    text-align: left;
}

ion-card-content div.agendamentos ion-label.agendamentosTexto {
    font-weight: bold;
    overflow: hidden;
    color: black;
    width: auto;
    overflow: hidden;

}


ion-slides.AgendamentosSlides ion-slide .pisca {
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.AgendamentosSlides ion-slide .piscaverde {
    animation-name: piscarverde;
}

ion-slides.AgendamentosSlides ion-slide .piscavermelho {
    animation-name: piscarvermelho;
}

ion-slides.AgendamentosSlides ion-slide .piscaamarelo {
    animation-name: piscaramarelo;
}
.relatorios input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

ion-slides.RelatoriosSlides {
    background: #F2F2F2;
    min-height: 100% !important;
}

ion-slides.RelatoriosSlides ion-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
    margin-top: 20px;
}

ion-slides.RelatoriosSlides .chartOperacoesAtivas {
    font-weight: bold;
    font-size: 70px;
}

ion-slides.RelatoriosSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.RelatoriosSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: medium;
    text-align: center;
}

.container .box {
    display: table;
    background: #eeeeee;
    width: 100%;
}

.container .box .box-row {
    display: table-row;
}

.container .box .box-cell {
    display: table-cell;
    width: 33%;
    padding: 10px;
}
table, th, td {
    border: 1px solid;
    border-color: #333;
    padding: 5px;
    font-size: 14px; 
  }

  tr:nth-child(even){background-color: #f2f2f2}

  th {
    background-color: #FFA508;
    color: black;
  }

  /*tr:hover {background-color: rgb(189, 189, 189);}*/

  .center {
    margin: auto;
    width: 90%;
    padding: 0px;
  }

  @media
	  only screen 
    and (max-width: 950px), (min-device-width: 950px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
      border: 0px solid;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "#"; }
		td:nth-of-type(2):before { content: "NF"; }
		td:nth-of-type(3):before { content: "Fornecedor"; }
		td:nth-of-type(4):before { content: "CNPJ"; }
		td:nth-of-type(5):before { content: "Tipo Entrega"; }
		td:nth-of-type(6):before { content: "Chegada"; }
		td:nth-of-type(7):before { content: "Término"; }
		td:nth-of-type(8):before { content: "Duração"; }
		td:nth-of-type(9):before { content: "Status Entrega"; }
		td:nth-of-type(10):before { content: "Motorista"; }
    td:nth-of-type(11):before { content: "Placa Veículo"; }


    /*
    Utilizar caso queira ocultar algum TD específico
    tr td:nth-child(1) { display:none; visibility:hidden; };
    */
	}

.ValidarTokenMotorista{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ValidarTokenMotorista .input{
    position: relative;
    margin: .5em 2em;
}

.ValidarTokenMotorista ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.ValidarTokenMotorista ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .ValidarTokenMotorista ion-input input{
    padding-left: 10px;
}

.ValidarTokenMotorista img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.ValidarTokenMotorista .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
ion-slides.HistoricoSlides {
    height: calc(100% - 3em);
}

ion-slides.HistoricoSlides{
    background:#F2F2F2;
    
}

ion-slides.HistoricoSlides .card{
    background:#F2F2F2;
    
}


ion-slides.HistoricoSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.HistoricoSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.HistoricoSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}

.Historico{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Historico ion-button{
    
    align-self: center;
    font-weight: bold;
}

.Historico ion-label.invisivel{
    color: transparent;
    display: none;
}

.Historico a{
    text-decoration: none;
}

ion-slides.HistoricoSlides{
    position: relative;
  height: auto;
  min-height: 100% !important;
}

ion-slides.HistoricoSlides {
    position: relative;
  height: auto;
  min-height: 100% !important;
}

ion-slides.HistoricoSlides ion-slide ion-card{
    width: calc(100% - 2em);
    margin: 1em;
}


ion-slides.HistoricoSlides ion-slide ion-card-title{
    font-weight: bold;
    font-size: medium;
    text-align: center;
}

ion-card.card{
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho{
    text-align: left;
    font-size: large;
}

ion-slides.HistoricoSlides ion-slide ion-card-subtitle{
    /*display: flex;*/
    text-align: center;
    /*margin: 0px;*/
}

ion-slides.HistoricoSlides ion-slide ion-card-subtitle > *{
    align-self: center;
    margin-right: .3em;
}

ion-slides.HistoricoSlides ion-slide ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 10px;
}

ion-slides.HistoricoSlides ion-slide .status{
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;
    
    right: 10px;
    display: flex;
    font-size: x-small;
}

@keyframes piscarverde {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(21, 255, 0);;
        background: rgb(18, 221, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscarvermelho {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 30, 0);;
        background: rgb(221, 0, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscaramarelo {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 217, 0);;
        background: rgb(255, 196, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

ion-slides.HistoricoSlides ion-slide .pisca{
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.HistoricoSlides ion-slide .piscaverde{
    animation-name: piscarverde;
}

ion-slides.HistoricoSlides ion-slide .piscavermelho{
    animation-name: piscarvermelho;
}

ion-slides.HistoricoSlides ion-slide .piscaamarelo{
    animation-name: piscaramarelo;
}

.ion-page .can-go-back ion-tab-bar {
    display: flex;
}


ion-header ion-toolbar {
    color: #333333;
}

/*
ion-content.conta {
    --background: #F2F2F2;
}
*/

/*
ion-footer ion-item ion-note.footeritemnote{
    font-size: medium;
}
*/


table, th, td {
    border: 1px solid;
    border-color: #333;
    padding: 5px;
    font-size: 14px; 
  }

  tr:nth-child(even){background-color: #f2f2f2}

  th {
    background-color: #FFA508;
    color: black;
  }

  /*tr:hover {background-color: rgb(189, 189, 189);}*/

  .center {
    margin: auto;
    width: 90%;
    padding: 0px;
  }

  @media
	  only screen 
    and (max-width: 950px), (min-device-width: 950px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
      border: 0px solid;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "#"; }
		td:nth-of-type(2):before { content: "Hora"; }
		td:nth-of-type(3):before { content: "CNPJ"; }
		td:nth-of-type(4):before { content: "Agendamento"; }
		td:nth-of-type(5):before { content: "Pedido"; }
		td:nth-of-type(6):before { content: "Descrição"; }
		td:nth-of-type(7):before { content: "Email"; }


    /*
    Utilizar caso queira ocultar algum TD específico
    tr td:nth-child(1) { display:none; visibility:hidden; };
    */
	}
.paginaRelatorioAgendamentos input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

.paginaRelatorioAgendamentos ion-select {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}

.react-datepicker-popper {
    z-index: 20 !important;
}

.painel {
    .item {
        --min-height: 0px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

.painel ion-card-content {
    padding: 0px;
}

.painel ion-card-content .item {
    font-size: x-small;
}

.painel ion-card-content .item ion-label {
    margin: 0px;
    min-height: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: x-small;
}

.painel ion-card {
    padding: 0px;
    margin: 0px;
}

.painel input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}
.monitor ion-grid.monitorListaEspera ion-row:nth-child(odd){
    background: #E0E0E0;
    text-wrap: nowrap;
    overflow: hidden;
}

.monitor ion-grid.monitorListaEspera ion-row:nth-child(even){
    background: white;
    text-wrap: nowrap;
    overflow: hidden;
}

.monitor ion-grid.monitorListaEspera ion-row:nth-child(1){
    background: grey;
    font-weight: bold;
}

table, th, td {
    border: 1px solid;
    border-color: #333;
    padding: 5px;
    font-size: 14px; 
  }

  tr:nth-child(even){background-color: #f2f2f2}

  th {
    background-color: #FFA508;
    color: black;
  }

  /*tr:hover {background-color: rgb(189, 189, 189);}*/

  .center {
    margin: auto;
    width: 90%;
    padding: 0px;
  }

  @media
	  only screen 
    and (max-width: 950px), (min-device-width: 950px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
      border: 0px solid;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
			white-space: normal;
			min-height: 30px;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
			min-height: 30px;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "#"; }
		td:nth-of-type(2):before { content: "DATA/HORA"; }
		td:nth-of-type(3):before { content: "FORNECEDOR"; }
		td:nth-of-type(4):before { content: "RECEBIDO"; }
		td:nth-of-type(5):before { content: "RECEBEDOR"; }
		td:nth-of-type(6):before { content: "INFORMAÇÕES/DEVOLVIDA"; }
		td:nth-of-type(7):before { content: "NF"; }
		td:nth-of-type(8):before { content: "CHAVE ACESSO"; }
		td:nth-of-type(9):before { content: "EMISSÃO"; }
		td:nth-of-type(10):before { content: "VENC.1"; }
		td:nth-of-type(11):before { content: "VENC.2"; }
		td:nth-of-type(12):before { content: "VENC.3"; }
    	td:nth-of-type(13):before { content: "CÓDIGO BARRAS"; }
		td:nth-of-type(14):before { content: "LIBERADO RECEBIMENTO"; }
		td:nth-of-type(15):before { content: "LIBERAÇÃO"; }

    /*
    Utilizar caso queira ocultar algum TD específico
    tr td:nth-child(1) { display:none; visibility:hidden; };
    */
	}

.ValidarCelular{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ValidarCelular .input{
    position: relative;
    margin: .5em 2em;
}

.ValidarCelular ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.ValidarCelular ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ValidarCelular #blocoTopo{
    width: 100%;
    height: 7em;
    background: var(--ion-color-cinza);
    margin-bottom: 5em;
}

.ValidarCelular .divisoria {
    position: relative;
    height: 1em;
    margin: .5em 2em .5em 2em;
}

.ValidarCelular .divisoria span{
    height: 2px;
    background: #CCC;
    width: 100%;
    display: block;
    transform: translateY(calc(1em / 2));
}

.ValidarCelular .divisoria ion-label{
    display: block;
    text-align: center;
    width: 7em;
    color: #CCC;
    background: white;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1em;
    line-height: 1em;
}

.ValidarCelular img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.ValidarCelular .jaTem{
    background: transparent;
    color: var(--ion-color-azul);
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: .25em 0em .25em 0em;
    
}

.ValidarCelular a{
    color: var(--ion-color-azul);
    text-decoration: none;
}

.ValidarCelular .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
.ValidarLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ValidarLogin .input{
    position: relative;
    margin: .5em 2em;
}

.ValidarLogin ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.ValidarLogin ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .ValidarLogin ion-input input{
    padding-left: 10px;
}

.ValidarLogin #blocoTopo{
    width: 100%;
    height: 7em;
    background: var(--ion-color-cinza);
    margin-bottom: 5em;
}

.ValidarLogin .divisoria {
    position: relative;
    height: 1em;
    margin: .5em 2em .5em 2em;
}

.ValidarLogin .divisoria span{
    height: 2px;
    background: #CCC;
    width: 100%;
    display: block;
    transform: translateY(calc(1em / 2));
}

.ValidarLogin .divisoria ion-label{
    display: block;
    text-align: center;
    width: 7em;
    color: #CCC;
    background: white;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1em;
    line-height: 1em;
}

.ValidarLogin img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.ValidarLogin .jaTem{
    background: transparent;
    color: var(--ion-color-azul);
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: .25em 0em .25em 0em;
    
}

.ValidarLogin a{
    color: var(--ion-color-azul);
    text-decoration: none;
}

.ValidarLogin .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
.ValidarLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ValidarLogin .input{
    position: relative;
    margin: .5em 2em;
}

.ValidarLogin ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.ValidarLogin ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .ValidarLogin ion-input input{
    padding-left: 10px;
}

.ValidarLogin img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.ValidarLogin .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
