.paginaAgendamentos input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

.paginaAgendamentos ion-select {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}

.react-datepicker-popper {
    z-index: 20 !important;
}

ion-content.agendamentoscontent {
    --background: #F2F2F2;
}

.Agendamentos {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Agendamentos ion-label.invisivel {
    color: transparent;
    display: none;
}

.Agendamentos a {
    text-decoration: none;
}

ion-slides.AgendamentosSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.AgendamentosSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.AgendamentosSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.AgendamentosSlides ion-slide ion-card ion-card-header {
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.AgendamentosSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: larger;
    text-align: left;
}



ion-card.card {
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho {
    text-align: left;
    font-size: large;
}


ion-slides.AgendamentosSlides ion-slide .status {
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;

    right: 10px;
    display: flex;
    font-size: x-small;
}

ion-card.agendamentosCard {
    margin-top: 30px;
}

ion-card-content div.agendamentos {
    display: flex;
    margin-top: 5px;
}

ion-card-content div.agendamentos ion-label {
    padding-right: 10px;
    min-width: 100px;
    overflow: hidden;
    text-align: left;
}

ion-card-content div.agendamentos ion-label.agendamentosTexto {
    font-weight: bold;
    overflow: hidden;
    color: black;
    width: auto;
    overflow: hidden;

}


ion-slides.AgendamentosSlides ion-slide .pisca {
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.AgendamentosSlides ion-slide .piscaverde {
    animation-name: piscarverde;
}

ion-slides.AgendamentosSlides ion-slide .piscavermelho {
    animation-name: piscarvermelho;
}

ion-slides.AgendamentosSlides ion-slide .piscaamarelo {
    animation-name: piscaramarelo;
}