.Login{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Login .input{
    position: relative;
    margin: .5em 2em;
}

.Login ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.Login ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .Login ion-input input{
    padding-left: 10px;
}

.Login #blocoTopo{
    width: 100%;
    height: 7em;
    background: var(--ion-color-cinza);
    margin-bottom: 5em;
}

.Login .divisoria {
    position: relative;
    height: 1em;
    margin: .5em 2em .5em 2em;
}

.Login .divisoria span{
    height: 1px;
    background: #CCC;
    width: 100%;
    display: block;
    transform: translateY(calc(1em / 2));
}

.Login .divisoria ion-label{
    display: block;
    text-align: center;
    width: 7em;
    color: #CCC;
    background: white;
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1em;
    line-height: 1em;
}

.Login img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.Login a{
    background: transparent;
    color: var(--ion-color-azul);
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: .25em 0em .25em 0em;
}

.Login .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}
.Login ion-button{
    background-color: var(--ion-color-branco);
}

.Login ion-button ion-label{
    width: 12em;
    text-align: left;
    margin-left: 1em;
}