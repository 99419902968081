ion-slides.HistoricoSlides {
    height: calc(100% - 3em);
}

ion-slides.HistoricoSlides{
    background:#F2F2F2;
    
}

ion-slides.HistoricoSlides .card{
    background:#F2F2F2;
    
}


ion-slides.HistoricoSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.HistoricoSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.HistoricoSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}

.Historico{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Historico ion-button{
    
    align-self: center;
    font-weight: bold;
}

.Historico ion-label.invisivel{
    color: transparent;
    display: none;
}

.Historico a{
    text-decoration: none;
}

ion-slides.HistoricoSlides{
    position: relative;
  height: auto;
  min-height: 100% !important;
}

ion-slides.HistoricoSlides {
    position: relative;
  height: auto;
  min-height: 100% !important;
}

ion-slides.HistoricoSlides ion-slide ion-card{
    width: calc(100% - 2em);
    margin: 1em;
}


ion-slides.HistoricoSlides ion-slide ion-card-title{
    font-weight: bold;
    font-size: medium;
    text-align: center;
}

ion-card.card{
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho{
    text-align: left;
    font-size: large;
}

ion-slides.HistoricoSlides ion-slide ion-card-subtitle{
    /*display: flex;*/
    text-align: center;
    /*margin: 0px;*/
}

ion-slides.HistoricoSlides ion-slide ion-card-subtitle > *{
    align-self: center;
    margin-right: .3em;
}

ion-slides.HistoricoSlides ion-slide ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 10px;
}

ion-slides.HistoricoSlides ion-slide .status{
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;
    
    right: 10px;
    display: flex;
    font-size: x-small;
}

@keyframes piscarverde {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(21, 255, 0);;
        background: rgb(18, 221, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscarvermelho {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 30, 0);;
        background: rgb(221, 0, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscaramarelo {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 217, 0);;
        background: rgb(255, 196, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

ion-slides.HistoricoSlides ion-slide .pisca{
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.HistoricoSlides ion-slide .piscaverde{
    animation-name: piscarverde;
}

ion-slides.HistoricoSlides ion-slide .piscavermelho{
    animation-name: piscarvermelho;
}

ion-slides.HistoricoSlides ion-slide .piscaamarelo{
    animation-name: piscaramarelo;
}