
.ion-page .can-go-back ion-tab-bar {
    display: flex;
}


ion-header ion-toolbar {
    color: #333333;
}

/*
ion-content.conta {
    --background: #F2F2F2;
}
*/

/*
ion-footer ion-item ion-note.footeritemnote{
    font-size: medium;
}
*/

