div.OperacoesSlides {
    padding-top:0px;
    height: calc(100% - 3em);
    background:#F2F2F2;
}

div.OperacoesSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

div.OperacoesSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
div.OperacoesSlides{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}


ion-segment ion-label{
    color: #000;
    font-size:17px;
    font-weight: bold;
}

.Operacoes{
    display: flex;
    flex-direction: column;
    overflow: hidden;

}

.Operacoes ion-label.invisivel{
    color: transparent;
    display: none;
}

.Operacoes a{
    text-decoration: none;
}

div.OperacoesSlides{

    position: relative;
  height: auto;
  min-height: 100% !important;
  
}

div.OperacoesSlides ion-card{
    
    width: calc(100% - 2em);
    margin: 1em;
    margin-top: 2em;
    overflow: visible;
}


div.OperacoesSlides ion-card-title{
    /*font-weight: bold;
    font-size: medium;*/
    text-align: left;
}

ion-card {
    border-radius: 10px;
}

ion-card.card{
    background-color: #F2F2F2;
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho{
    text-align: left;
    font-size: large;
}

div.OperacoesSlides ion-card-content{
    /*display: flex;*/
    text-align: left;
    /*margin: 0px;*/
}



ion-card-content div.operacao{
    display:flex;
    margin-top:5px;
    
}

ion-card-content div.operacao ion-label{
    padding-right: 10px;
    width: 100px;
    overflow: hidden;
}

ion-card-content div.operacao ion-label.operacaoTexto{
    font-weight: bold;
    color: black;
    width:auto;
}

ion-card-content div.operacao ion-icon{
    margin-left:auto;
    width:18px;
    height:18px;
}


ion-card-content div.cardTail{
    margin-top: 15px;
    /*display: inline-block;*/
    display:flex;
}

div.OperacoesSlides ion-card-content span{
    display:block;
    margin: auto;
    text-align: center;
    font-weight: bold;
    color: var(--ion-color-primary);

}

div.OperacoesSlides .status{
    position: absolute;
    bottom: 15px;
    right: 10px;
    display: flex;
    font-size: x-small;
}

div.OperacoesSlides ion-card-subtitle{
    /*display: flex;*/
    text-align: center;
    /*margin: 0px;*/
}

div.OperacoesSlides ion-card-subtitle > *{
    align-self: center;
    margin-right: .3em;
}

div.OperacoesSlides ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 10px;
}

div.tempo div.duracao{
    margin: 0;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: -10px;
    
}

div.tempo div.duracaoTexto{
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align:center;
}

div.tempo{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -30px;
    margin: auto;
    right: 10px;
    left: 10px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--ion-color-tertiary); /*#7fee1d;*/
    z-index: 999;
}

div.loja{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin:0;
    padding: 4px;
    left: 0px;
    color: black;
    /*left: 0px;    */
    width: auto;
    height: 25px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    background: var(--ion-color-primary); /*#7fee1d;*/
    /*z-index: 999;*/
}

div.tempo2{
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin:auto;
    right: 10px;
    /*left: 0px;    */
    width: 200px;
    height: 25px;
    border-radius: 10px 10px 0 0;
    background: var(--ion-color-tertiary); /*#7fee1d;*/
    z-index: 999;
}

div.tempo2 div.duracao{
    margin: 0;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: -10px;
    
}

div.tempo2 div.duracaoTexto{
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align:center;
}

@keyframes piscarverde {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(21, 255, 0);;
        background: rgb(18, 221, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscarvermelho {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 30, 0);;
        background: rgb(221, 0, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscaramarelo {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
    50% {
        box-shadow: 0px 0px 10px rgb(255, 217, 0);;
        background: rgb(255, 196, 0);
    }
    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

div.OperacoesSlides {
    background: #F2F2F2;
}

idiv.OperacoesSlides .pisca{
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

div.OperacoesSlides .piscaverde{
    animation-name: piscarverde;
}

div.OperacoesSlides .piscavermelho{
    animation-name: piscarvermelho;
}

div.OperacoesSlides .piscaamarelo{
    animation-name: piscaramarelo;
}