ion-slides.OnboardingSlides {
    height: calc(100% - 10em);
}

ion-slides.OnboardingSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.OnboardingSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.OnboardingSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 50px;
    
}
ion-slides.OnboardingSlides ion-slide h1{
    width: 100%;
    font-size: 1.7em;
    font-family: Nunito;
    font-weight: bold;
    margin: 3em 1em 0.5em 1em;
}

ion-slides.OnboardingSlides ion-slide h2{
    width: 100%;
    font-size: 1.3em;
    margin: 0em 1em 2em 1em;
    padding: 0em 1em;
}

ion-slides.OnboardingSlides ion-slide img{
    width: 15em;
}

.Onboarding{
    display: flex;
    flex-direction: column;
}

.Onboarding ion-button{
    width: calc(100% - 4em);
    height: 3em;
    margin: 1em 2em;
    align-self: center;
}

.Onboarding ion-label{
    color: var(--ion-color-tertiary);
    width: 100%;
    
    display: block;
    text-align: center;

    transition: color ease 0.2s, display ease 0.5s;
}

.Onboarding ion-label.invisivel{
    color: transparent;
    display: none;
}

.Onboarding a{
    text-decoration: none;
}