.relatorios input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

ion-slides.RelatoriosSlides {
    background: #F2F2F2;
    min-height: 100% !important;
}

ion-slides.RelatoriosSlides ion-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
    margin-top: 20px;
}

ion-slides.RelatoriosSlides .chartOperacoesAtivas {
    font-weight: bold;
    font-size: 70px;
}

ion-slides.RelatoriosSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.RelatoriosSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: medium;
    text-align: center;
}

.container .box {
    display: table;
    background: #eeeeee;
    width: 100%;
}

.container .box .box-row {
    display: table-row;
}

.container .box .box-cell {
    display: table-cell;
    width: 33%;
    padding: 10px;
}