/*
:root {
    --ion-safe-area-top: 20px;
    --ion-safe-area-bottom: 22px;
  }
*/

.agendamentoCadContent .calendar input {
  border: none;
  outline: none;
  text-align: left;
  width: 80px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin: 0;
  margin-left: 8px;
  padding: 0;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
  background: white;
}

.agendamentoCadContent .calendar ion-select {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}

