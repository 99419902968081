ion-slides.InstrucoesSlides .swiper-pagination-bullet{
    background: #333;
    opacity: 0.3;
}

ion-slides.InstrucoesSlides .swiper-pagination-bullet-active{
    background: #3880ff;
    opacity: 1;
}
ion-slides.InstrucoesSlides ion-slide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

ion-slides.InstrucoesSlides ion-slide ion-card{
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.InstrucoesSlides ion-slide ion-card ion-card-header{
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.InstrucoesSlides ion-slide ion-card-title{
    font-weight: bold;
    font-size: larger;
    text-align: left;
}

ion-card-content div.cardInstrucoes{
    display:flex;
    margin-top:5px;    
}

.ios ion-label.instrucoes
{
    margin-top: 20px;

}

