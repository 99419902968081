.paginaRelatorioAgendamentos input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}

.paginaRelatorioAgendamentos ion-select {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
}

.react-datepicker-popper {
    z-index: 20 !important;
}
