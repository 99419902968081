/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
html, html * {
  font-family: 'Open Sans', sans-serif;
}


/** Ionic CSS Variables **/

:root {
  --ion-color-amarelo: #FFA508;
  --ion-color-laranja: #ff6a14;
  --ion-color-azul: #1d3460;
  --ion-color-branco: #e1e1e1;
  --ion-color-cinza: #333333;
  --ion-color-facebook: #3B5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
  --ion-color-google: #538AF0;
  --ion-color-google-rgb: 83, 138, 240;
  --ion-color-google-contrast: #000000;
  --ion-color-google-contrast-rgb: 0, 0, 0;
  --ion-color-google-shade: #4979d3;
  --ion-color-google-tint: #6496f2;
  --ion-color-primary: #FFA508;
  --ion-color-primary-rgb: 255, 165, 8;
  --ion-color-primary-contrast: #1d3460;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e09107;
  --ion-color-primary-tint: #ffae21;
  --ion-color-secondary: #ff6a14;
  --ion-color-secondary-rgb: 255, 106, 20;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e05d12;
  --ion-color-secondary-tint: #ff792c;
  --ion-color-tertiary: #1D3460;
  --ion-color-tertiary-rgb: 29, 52, 96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1a2e54;
  --ion-color-tertiary-tint: #344870;
  --ion-color-quartiary: rgba(0, 0, 0, 0);
  --ion-color-quartiary-shade: rgba(0, 0, 0, 0);
  --ion-color-quartiary-tint: rgba(0, 0, 0, 0);
  --ion-color-quartiary-rgb: 0, 0, 0;
  --ion-color-quartiary-contrast: #ffffff;
  --ion-color-quartiary-contrast-rgb: 255, 255, 255;
  --ion-color-success: #33ac1b;
  --ion-color-success-rgb: 51, 172, 27;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2d9718;
  --ion-color-success-tint: #47b432;
  --ion-color-warning: #F2F2F2;
  --ion-color-warning-rgb: 251, 198, 4;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ddae04;
  --ion-color-warning-tint: #fbcc1d;
  --ion-color-danger: #d4251c;
  --ion-color-danger-rgb: 212, 37, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bb2119;
  --ion-color-danger-tint: #d83b33;
  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-color-medium: #333333;
  --ion-color-medium-rgb: 51, 51, 51;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #2d2d2d;
  --ion-color-medium-tint: #474747;
  --ion-color-light: #FFFFFF;
  /*#e1e1e1;*/
  --ion-color-light-rgb: 225, 225, 225;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c6c6c6;
  --ion-color-light-tint: #F2F2F2;
  /*Reservado para uso exclusivo de IOS e Android*/
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 0px;
  /*--ion-safe-area-bottom: 22px;*/
}

ion-button {
  text-transform: none;
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
}

/*
   * Dark Colors
   * -------------------------------------------
   */

/*
@media (prefers-color-scheme: dark) {
  

  body {
    --ion-color-primary: #FFA508;
  --ion-color-primary-rgb: 255,165,8;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e09107;
  --ion-color-primary-tint: #ffae21;

  --ion-color-secondary: #ff6a14;
  --ion-color-secondary-rgb: 255,106,20;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e05d12;
  --ion-color-secondary-tint: #ff792c;

  --ion-color-tertiary: #1d3460;
  --ion-color-tertiary-rgb: 29,52,96;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #1a2e54;
  --ion-color-tertiary-tint: #344870;

  --ion-color-success: #33ac1b;
  --ion-color-success-rgb: 51,172,27;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #2d9718;
  --ion-color-success-tint: #47b432;

  --ion-color-warning: #fbc604;
  --ion-color-warning-rgb: 251,198,4;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #ddae04;
  --ion-color-warning-tint: #fbcc1d;

  --ion-color-danger: #d4251c;
  --ion-color-danger-rgb: 212,37,28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #bb2119;
  --ion-color-danger-tint: #d83b33;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #333333;
  --ion-color-medium-rgb: 51,51,51;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #2d2d2d;
  --ion-color-medium-tint: #474747;

  --ion-color-light: #e1e1e1;
  --ion-color-light-rgb: 225,225,225;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #c6c6c6;
  --ion-color-light-tint: #e4e4e4;
  }

  // iOS Dark Theme

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  //Material Design Dark Theme

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/