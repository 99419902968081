.painel {
    .item {
        --min-height: 0px;
        height: fit-content;
    }
}

.painel ion-card-content {
    padding: 0px;
}

.painel ion-card-content .item {
    font-size: x-small;
}

.painel ion-card-content .item ion-label {
    margin: 0px;
    min-height: 0px;
    height: fit-content;
    font-size: x-small;
}

.painel ion-card {
    padding: 0px;
    margin: 0px;
}

.painel input {
    border: none;
    outline: none;
    text-align: center;
    width: 80px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    background: white;
}