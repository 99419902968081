.monitor ion-grid.monitorListaEspera ion-row:nth-child(odd){
    background: #E0E0E0;
    text-wrap: nowrap;
    overflow: hidden;
}

.monitor ion-grid.monitorListaEspera ion-row:nth-child(even){
    background: white;
    text-wrap: nowrap;
    overflow: hidden;
}

.monitor ion-grid.monitorListaEspera ion-row:nth-child(1){
    background: grey;
    font-weight: bold;
}
