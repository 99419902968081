.ValidarLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ValidarLogin .input{
    position: relative;
    margin: .5em 2em;
}

.ValidarLogin ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.ValidarLogin ion-input{
    width: 100%;
    height: 2.75em;
    border: 1px solid var(--ion-color-cinza);
    border-radius: 7px;
}

.ios .ValidarLogin ion-input input{
    padding-left: 10px;
}

.ValidarLogin img{
    position: absolute;
    top: 7em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 7em;
    border: 1px solid var(--ion-color-cinza);
    transform: translateY(-50%);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.ValidarLogin .titulo{
    background: transparent;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: block;
    margin: 1em 0em 1em 0em;
}